/**
 * Base
 * =================================================================================================
 */
.Contact {
	line-height: 1.1;
}

/**
 * Header
 * =================================================================================================
 */
.Header {
	font-family: RingsideCompressed-Ultra, -apple-system, sans-serif;
	text-transform: uppercase;
	transform: translateX(-0.05em);
}

.Header h3,
.Header a {
	color: rgba(0, 0, 0, 0.35);
}

.Header a:hover {
	color: rgba(0, 0, 0, 0.5);
}

@media (min-width: 0) and (max-width: 767px) {
	.Header {
		font-size: 3rem;
		line-height: 0.9em;
	}
}

@media (min-width: 768px) and (max-width: 1023px) {
	.Header {
		font-size: 4rem;
		line-height: 0.9em;
		padding-left: calc(1 / 3 * 100%);
	}
}

@media (min-width: 1024px) and (max-width: 1365px) {
	.Header {
		font-size: 4.8rem;
		line-height: calc(48 / 48);
		padding-left: calc(1 / 3 * 100%);
		padding-right: calc(1 / 6 * 100%);
	}
}

@media (min-width: 1366px) and (max-width: 1919px) {
	.Contact {
		/* margin-left: calc(1 / 6 * 100%);
		margin-right: calc(1 / 6 * 100%); */
		position: relative;
	}

	.Header {
		font-size: 5.2rem;
		line-height: calc(46 / 48);
		padding-left: calc(1 / 3 * 100%);
		padding-right: calc(1 / 6 * 100%);
	}
}

@media (min-width: 1920px) {
	.Contact {
		flex: 1;
		order: 1;
	}

	.Header {
		font-size: 4.8rem;
		line-height: calc(48 / 48);
		padding-left: calc(1 / 4 * 100%);
	}
}
