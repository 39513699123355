.Timeline {
	-webkit-overflow-scrolling: touch;
	overflow-x: scroll;
}

.Timeline::-webkit-scrollbar {
	background: transparent; /* make scrollbar transparent */
	width: 0px;
}

.Navigation {
	display: flex;
	font-family: RingsideCompressedSSm-Black;
	position: relative;
	text-transform: uppercase;
}

/**
 * Timeline Background
 * =================================================================================================
 */
.Navigation::before {
	background: black;
	border-radius: 18px;
	content: "";
	height: 4px;
	left: 0;
	position: absolute;
	right: 36px;
	top: -4px;
}

.Release {
	flex: 1;
	position: relative;
}

.Release::before {
	background: white;
	border-radius: 10px;
	box-shadow: 0 0 0 4px black;
	content: "";
	height: 10px;
	left: 0;
	position: absolute;
	top: -7px;
	transition:
		background-color 450ms cubic-bezier(0.19, 1, 0.22, 1) 100ms,
		box-shadow 300ms cubic-bezier(0.19, 1, 0.22, 1) 600ms;
	width: 10px;
	will-change: background-color;
}

.Release--selected .Title {
	color: hsl(7, 79%, 55%);
}

.Release--selected.Release::before {
	background-color: rgb(80, 80, 80);
	background-color: hsl(7, 79%, 55%);
}

:global(.caught-by-a-new-invention) .Release:not(.Release--selected)::before {
	background-color: hsl(213, 22%, 32%);
}

:global(.the-family-tree) .Release:not(.Release--selected)::before {
	background-color: hsl(109, 0%, 31%);
}

:global(.the-long-wind-down) .Release:not(.Release--selected)::before {
	background-color: hsl(235, 5%, 35%);
}

:global(.insound-tour-support-series-vol-24) .Release:not(.Release--selected)::before {
	background-color: hsl(64, 1%, 78%);
}

:global(.cedarland) .Release:not(.Release--selected)::before {
	background-color: hsl(193, 50%, 28%);
}

:global(.twelve-rooms) .Release:not(.Release--selected)::before {
	background-color: hsl(174, 63%, 84%);
}

:global(.insound-tour-support-series-vol-24) .Release--selected .Title,
:global(.the-long-wind-down) .Release--selected .Title,
:global(.twelve-rooms-special-edition) .Release--selected .Title,
:global(.caught-by-a-new-invention) .Release--selected .Title,
:global(.the-family-tree) .Release--selected .Title,
:global(.cedarland) .Release--selected .Title {
	color: white;
}

:global(.wilderness) .Release--selected .Title,
:global(.twelve-rooms) .Release--selected .Title {
	color: hsl(7, 79%, 55%);
}

:global(.animals-vol-two) .Release--selected .Title,
:global(.animals-vol-one) .Release--selected .Title {
	color: black;
}

:global(.wilderness) .Release:not(.Release--selected)::before,
:global(.twelve-rooms-special-edition) .Release:not(.Release--selected)::before {
	background-color: hsl(355, 10%, 56%);
}

:global(.wilderness) .Release:not(.Release--selected)::before {}

:global(.animals-vol-two) .Release:not(.Release--selected)::before,
:global(.animals-vol-one) .Release:not(.Release--selected)::before {
	background-color: hsl(109, 0%, 35%);
}

.Date {
	bottom: calc(100% + 12px);
	display: block;
	font-family: RingsideRegularSSm-Bold;
	font-size: 0.6em;
	position: absolute;
}

/**
 * Title (album name)
 * =================================================================================================
 */
.Title {
	color: inherit;
	display: block;
	text-decoration: none;
	transition: color 300ms cubic-bezier(0.19, 1, 0.22, 1) 600ms;
}

.Title:hover {
	color: hsl(7, 79%, 55%);
	transition-delay: 0ms;
	transition-duration: 0ms;
}


/**
 * Media Queries
 * =================================================================================================
 */
@media (min-width: 0) and (max-width: 767px) {
	.Timeline {
		margin: 54px -18px 30px;
		padding: 54px 18px 30px;
		width: calc(100% + 36px);
	}

	.Navigation {
		font-size: 1.8rem;
		line-height: 1.1em;
		width: calc((1 / 3 * 100%) * 10);
	}

	.Navigation::before { right: 18px; }
	.Date { padding-bottom: 4px; }

	.Title {
		max-width: 66%;
		padding-top: 18px;
	}

	.Release:last-child .Title {
		padding-right: 0;
	}
}

@media (min-width: 768px) and (max-width: 1023px) {
	.Timeline {
		margin: 54px -36px 30px;
		padding: 54px 36px 30px;
		width: calc(100% + 72px);
	}

	.Navigation {
		font-family: RingsideCompressed-Ultra;
		font-size: 2rem;
		line-height: 1.1em;
		width: calc((1 / 6 * 100%) * 10);
	}

	.Date { padding-bottom: 4px; }

	.Title {
		padding-right: 9px;
		padding-top: 18px;
	}
}

@media (min-width: 1024px) and (max-width: 1365px) {
	.Timeline {
		margin: 54px -36px 30px;
		padding: 54px 36px 30px;
		width: calc(100% + 72px);
	}

	.Navigation {
		font-family: RingsideCompressed-Ultra;
		font-size: 2.2rem;
		line-height: 1.1em;
		width: calc((1 / 6 * 100%) * 10);
	}

	.Navigation::before {
		height: 6px;
		top: -6px;
	}

	.Release::before {
		box-shadow: 0 0 0 5px black;
		top: -8px;
	}

	.Date {
		font-family: RingsideRegularSSm-Black;
		padding-bottom: 6px;
	}

	.Title {
		padding-right: 9px;
		padding-top: 18px;
	}
}

@media (min-width: 1366px) and (max-width: 1919px) {
	.Timeline {
		margin: 54px -36px 30px;
		padding: 54px 36px 30px;
		width: calc(100% + 72px);
	}

	.Navigation {
		font-family: RingsideCompressed-Ultra;
		font-size: 2.7rem;
		line-height: 1.1em;
		width: calc((1 / 6 * 100%) * 10);
	}

	.Navigation::before {
		height: 6px;
		top: -6px;
	}

	.Release::before {
		box-shadow: 0 0 0 5px black;
		top: -8px;
	}

	.Date {
		font-family: RingsideRegularSSm-Black;
		padding-bottom: 6px;
	}

	.Title {
		padding-right: 18px;
		padding-top: 18px;
	}
}

@media (min-width: 1920px) {
	.Timeline {
		align-items: flex-end;
		display: flex;
		height: calc(100vh - 72px);
		order: 2;
		overflow: visible;
		padding: 0;
		width: 12vw;
	}

	.Navigation {
		display: block;
		font-family: RingsideCompressed-Bold;
		font-size: 2.2rem;
		line-height: 1.05em;
		max-height: 100%;
		padding: 0 36px;
	}

	.Navigation li + li {
		margin-top: 0.5em;
	}

	.Navigation::before,
	.Release::before {
		display: none;
	}

	.Date {
		display: block;
		font-family: RingsideRegularSSm-Med;
		font-size: 0.6em;
		padding-bottom: 0;
		position: static;
	}
}
