@import './styles/reset.css';

html {
	font-size: 10px;
}

body {
	background-color: hsl(10, 72%, 91%);
	background-color: white;
	color: black;
	font-family:
		RingsideRegular-Book,
		-apple-system,
		BlinkMacSystemFont,
		'Segoe UI',
		Roboto,
		Oxygen,
		Ubuntu,
		Cantarell,
		'Fira Sans',
		'Droid Sans',
		'Helvetica Neue',
		sans-serif;
	font-feature-settings: "tnum" 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

a {
	color: hsl(7, 79%, 55%);
	outline: none;
	-webkit-tap-highlight-color: transparent;
}

.twelve-rooms-special-edition a,
.animals-vol-one a,
.animals-vol-two a {
	color: rgba(255, 255, 255, 0.55);
}

.twelve-rooms-special-edition a:hover,
.twelve-rooms-special-edition a:focus,
.animals-vol-one a:hover,
.animals-vol-one a:focus,
.animals-vol-two a:hover,
.animals-vol-two a:focus {
	color: white;
}

.insound-tour-support-series-vol-24 a,
.the-long-wind-down a,
.caught-by-a-new-invention a,
.cedarland a,
.the-family-tree a {
	color: rgba(255, 255, 255, 0.3);
}

.insound-tour-support-series-vol-24 a:hover,
.insound-tour-support-series-vol-24 a:focus,
.the-long-wind-down a:hover,
.the-long-wind-down a:focus,
.caught-by-a-new-invention a:hover,
.caught-by-a-new-invention a:focus,
.cedarland a:hover,
.cedarland a:focus,
.the-family-tree a:hover,
.the-family-tree a:focus {
	color: white;
	transition-delay: 0ms;
	transition-duration: 0ms;
}

.animals-vol-one a,
.animals-vol-two a,
.twelve-rooms a,
.wilderness a {
	color: rgba(0, 0, 0, 0.35);
}

.twelve-rooms a:hover,
.twelve-rooms a:focus,
.wilderness a:hover,
.wilderness a:focus {
	color: hsl(7, 79%, 55%);
	transition-delay: 0ms;
	transition-duration: 0ms;
}

.animals-vol-one a:hover,
.animals-vol-one a:focus,
.animals-vol-two a:hover,
.animals-vol-two a:focus {
	color: black;
}
