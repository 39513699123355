/**
 * Base
 * =================================================================================================
 */
.Four {
	line-height: 1.1;
}

.Four h1 {
	font-family: Tungsten-Bold;
	font-size: 30vw;
	line-height: 0.7;
}

@media (min-width: 1366px) and (max-width: 1919px) {
	.Four {
		margin-left: calc(1 / 6 * 100%);
		margin-right: calc(1 / 6 * 100%);
		position: relative;
	}
}

@media (min-width: 1920px) {
	.Four {
		flex: 1;
		order: 1;
	}
}
