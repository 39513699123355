/*
================================================================================
	Box Sizing
============================================================================= */
html {
	box-sizing: border-box;
}

body {
	font-size: 12px;
	font-weight: normal;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

/*
================================================================================
	Generic Margins and Paddings
============================================================================= */
body,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
li,
p,
pre,
blockquote,
figure,
hr {
	font-weight: normal;
	margin: 0;
	padding: 0;
}

/*
================================================================================
	Headlines
============================================================================= */
h1,
h2,
h3,
h4,
h5,
h6 { font-size: inherit; }

/*
================================================================================
	Paragraphs
============================================================================= */
p {
	margin: 0;
	padding: 0;
}

/*
================================================================================
	Strong and Emphasis
============================================================================= */
strong {
	font-weight: normal;
}

em {
	font-style: normal;
}

/*
================================================================================
	Lists
============================================================================= */
ul {
	list-style: none;
}


/*
================================================================================
	Forms and buttons
============================================================================= */
fieldset {
	border: 0;
	margin: 0;
	min-width: 0;
	padding: 0;
}

legend {
	display: table;
	margin: 0;
	padding: 0;
	width: 100%;
}

input,
textarea,
select,
button {
	color: inherit;
	font: inherit;
	letter-spacing: inherit;
}

/* I usually expand input and textarea to full-width */
input[type='text'],
textarea {
	width: 100%;
}

/* More friendly border */
input,
textarea,
button {
	border: 1px solid gray;
}

/* Some defaults for one-liner buttons */
button {
	appearance: none;
	background-color: transparent;
	border: none;
	border-radius: 0;
	cursor: pointer;
	line-height: 1;
	margin: 0;
	outline: none;
	padding: 0;
}

button * {
	pointer-events: none;
}

/*
================================================================================
	Easy responsive for media elements
============================================================================= */
img,
svg,
iframe,
video,
object,
embed {
	display: block;
	max-width: 100%;
}

/*
================================================================================
	Useful table styles
============================================================================= */
table {
	/* table-layout: fixed; */
	width: 100%;
}

/*
================================================================================
	The hidden attribute
============================================================================= */
[hidden] {
	display: none !important;
}

/*
================================================================================
	Noscript
============================================================================= */
noscript {
	display: block;
	margin-bottom: 1em;
	margin-top: 1em;
}
