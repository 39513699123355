:root {
	--color--light: rgb(252, 249, 233);
	--color--dark: rgba(51, 49, 45, 1);
	--border-radius--small: 4px;
	--border-radius--medium: 10px;
	--element-shadow--small:
		0 0 0 3px var(--color--dark),
		7px 7px 0 rgba(0, 0, 0, 0.7);
	--element-shadow--medium:
		0 0 0 3px var(--color--dark),
		10px 10px 0 rgba(0, 0, 0, 0.7);
	--element-shadow--large:
		0 0 0 3px var(--color--dark),
		12px 12px 0 1px rgba(0, 0, 0, 0.7);
	--progress-segment-size: 7px;
}

@keyframes fadeIn {
	from {
		opacity: 0;
		transform: scale(1);
	}

	to {
		opacity: 1;
		transform: scale(1);
	}
}

@keyframes scroll {
	from { background-position: 0 0; }
	to { background-position: 42px 0; }
}

/**
 * Webfonts
 * =================================================================================================
 */
@font-face {
	font-family: RingsideCompressedSSm-Black;
	src: url('~assets/fonts/RingsideCompressedSSm-Black.otf') format('opentype');
}

@font-face {
	font-family: RingsideCompressed-Ultra;
	src: url('~assets/fonts/RingsideCompressed-Ultra.otf') format('opentype');
}

@font-face {
	font-family: RingsideExtraWide-Ultra;
	src: url('~assets/fonts/RingsideExtraWide-Ultra.otf') format('opentype');
}

@font-face {
	font-family: RingsideRegular-Bold;
	src: url('~assets/fonts/RingsideRegular-Bold.otf') format('opentype');
}

@font-face {
	font-family: RingsideRegular-BookItal;
	src: url('~assets/fonts/RingsideRegular-BookItal.otf') format('opentype');
}

@font-face {
	font-family: RingsideRegular-Light;
	src: url('~assets/fonts/RingsideRegular-Light.otf') format('opentype');
}

@font-face {
	font-family: RingsideRegular-Med;
	src: url('~assets/fonts/RingsideRegular-Med.otf') format('opentype');
}

@font-face {
	font-family: RingsideRegularSSm-Black;
	src: url('~assets/fonts/RingsideRegularSSm-Black.otf') format('opentype');
}

@font-face {
	font-family: RingsideRegularSSm-Bold;
	src: url('~assets/fonts/RingsideRegularSSm-Bold.otf') format('opentype');
}

@font-face {
	font-family: RingsideRegularSSm-Book;
	src: url('~assets/fonts/RingsideRegularSSm-Book.otf') format('opentype');
}

@font-face {
	font-family: RingsideRegularSSm-BookItal;
	src: url('~assets/fonts/RingsideRegularSSm-BookItal.otf') format('opentype');
}

@font-face {
	font-family: RingsideRegularSSm-Light;
	src: url('~assets/fonts/RingsideRegularSSm-Light.otf') format('opentype');
}

@font-face {
	font-family: RingsideRegularSSm-LightItal;
	src: url('~assets/fonts/RingsideRegularSSm-LightItal.otf') format('opentype');
}

@font-face {
	font-family: RingsideRegularSSm-Med;
	src: url('~assets/fonts/RingsideRegularSSm-Med.otf') format('opentype');
}

@font-face {
	font-family: Tungsten-Bold;
	src: url('~assets/fonts/Tungsten-Bold.otf') format('opentype');
}

@font-face {
	font-family: RingsideCompressed-Bold;
	src: url('~assets/fonts/RingsideCompressed-Bold.otf') format('opentype');
}

@font-face {
	font-family: RingsideRegular-BoldItal;
	src: url('~assets/fonts/RingsideRegular-BoldItalic.otf') format('opentype');
}

@font-face {
	font-family: RingsideRegular-Book;
	src: url('~assets/fonts/RingsideRegular-Book.otf') format('opentype');
}

@font-face {
	font-family: RingsideRegularSSm-BoldItal;
	src: url('~assets/fonts/RingsideRegularSSm-BoldItalic.otf') format('opentype');
}

/**
 * Base
 * =================================================================================================
 */
.App {
	background-color: white;
	line-height: 1.1;
	transition:
		background-color 300ms cubic-bezier(0.19, 1, 0.22, 1) 600ms,
		color 300ms cubic-bezier(0.19, 1, 0.22, 1) 600ms;
	will-change: background-color, color;
}

.App--home {
	justify-content: flex-end;
}

@media (min-width: 0) and (max-width: 767px) {
	.App {
		padding: 18px 18px 120px;
	}
}

@media (min-width: 768px) and (max-width: 1919px) {
	.App {
		padding: 36px 36px 120px;
	}
}

@media (min-width: 1920px) {
	.App {
		align-items: flex-start;
		display: flex;
		padding: 36px;
	}
}

/**
 * Themes
 * =================================================================================================
 */
.App--home,
.App--contact,
.App--about {
	background-color: transparent;
	color: black;
}

.App:global(.cedarland) {
	background-color: rgb(36, 92, 107);
	color: rgba(255, 255, 255, 0.8);
}

.App:global(.caught-by-a-new-invention) {
	background-color: hsl(213, 22%, 32%);
	color: rgba(255, 255, 255, 0.85);
}

.App:global(.the-long-wind-down) {
	background-color: hsl(234, 5%, 35%);
	color: rgba(255, 255, 255, 0.85);
}

.App:global(.the-family-tree) {
	background-color: rgb(80, 80, 80);
	color: rgba(255, 255, 255, 0.8);
}

.App:global(.insound-tour-support-series-vol-24) {
	background-color: hsl(213, 22%, 38%);
	color: rgba(255, 255, 255, 0.8);
}

.App:global(.twelve-rooms) {
	background-color: hsl(174, 63%, 84%);
}

.App:global(.twelve-rooms-special-edition) {
	background-color: rgb(154, 132, 134);
}

.App:global(.wilderness) {
	background-color: hsl(32, 1%, 78%);
}

.App:global(.animals-vol-one),
.App:global(.animals-vol-two) {
	background-color: rgb(135, 135, 135);
}

.Grid {
	bottom: 36px;
	box-shadow: 0 0 0 1px blue;
	left: 36px;
	pointer-events: none;
	position: fixed;
	right: 36px;
	top: 36px;
	z-index: 10;
}

.Grid div {
	background: blue;
	bottom: 0;
	position: absolute;
	top: 0;
	width: 1px;
}

.Grid div:nth-child(1) { left: calc(1 / 6 * 100% - 1px); }
.Grid div:nth-child(2) { left: calc(2 / 6 * 100% - 1px); }
.Grid div:nth-child(3) { left: calc(3 / 6 * 100% - 1px); }
.Grid div:nth-child(4) { left: calc(4 / 6 * 100% - 1px); }
.Grid div:nth-child(5) { left: calc(5 / 6 * 100% - 1px); }

@media (min-width: 0) and (max-width: 767px) {
	.Grid {
		bottom: 18px;
		left: 18px;
		right: 18px;
		top: 18px;
	}
}

.CloseVideos {
	align-items: center;
	background-color: var(--color--light);
	border-radius: var(--border-radius--medium);
	box-shadow: var(--element-shadow--medium);
	color: var(--color--dark) !important;
	display: flex;
	font-family: RingsideRegular-Bold;
	font-size: 4rem;
	height: 48px;
	justify-content: center;
	padding: 4px 14px;
	position: absolute;
	right: 36px;
	text-decoration: none;
	top: 36px;
	width: 48px;
}

.CloseVideos:hover {
	background-color: var(--color--dark);
	color: white !important;
}

.PlayIcon {
	align-items: center;
	background-color: var(--color--light);
	border-radius: 8px;
	box-shadow: var(--element-shadow--medium);
	color: var(--color--dark);
	display: flex;
	height: 48px;
	justify-content: center;
	width: 48px;
}

.PlayIcon:hover {
	box-shadow: var(--element-shadow--large);
	transform: translate3d(-1px, -1px, 0);
}

.Videos {
	animation-duration: 250ms;
	animation-fill-mode: both;
	animation-name: fadeIn;
	bottom: 0;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	will-change: opacity, transform;
	z-index: 200;
}

.Videos::before {
	background-color: tomato;
	bottom: 0;
	content: "";
	left: 0;
	opacity: 0.9;
	position: absolute;
	right: 0;
	top: 0;
}

.App:global(.cedarland) .Videos::before { background-color: rgb(36, 92, 107); }
.App:global(.caught-by-a-new-invention) .Videos::before { background-color: hsl(213, 22%, 32%); }
.App:global(.the-long-wind-down) .Videos::before { background-color: hsl(234, 5%, 35%); }
.App:global(.the-family-tree) .Videos::before { background-color: rgb(80, 80, 80); }
.App:global(.insound-tour-support-series-vol-24) .Videos::before { background-color: hsl(213, 22%, 38%); }
.App:global(.twelve-rooms) .Videos::before { background-color: hsl(174, 63%, 84%); }
.App:global(.twelve-rooms-special-edition) .Videos::before { background-color: rgb(154, 132, 134); }
.App:global(.wilderness) .Videos::before { background-color: hsl(32, 1%, 78%); }

.App:global(.animals-vol-one) .Videos::before,
.App:global(.animals-vol-two) .Videos::before { background-color: rgb(135, 135, 135); }

.Video {
	background-color: var(--color--light);
	border-radius: var(--border-radius--medium);
	box-shadow: var(--element-shadow--medium);
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow: hidden;
	width: 100%;
}

.VideoHeader {
	align-items: center;
	border-bottom: solid 3px var(--color--dark);
	cursor: move;
	display: flex;
	flex-shrink: 0;
	height: 48px;
	justify-content: center;
	padding: 0 24px;
	position: relative;
	text-align: center;
}

.VideoHeader::before {
	background-image:
		linear-gradient(
			to bottom,
			var(--color--dark),
			var(--color--dark) 50%,
			var(--color--light) 50%,
			var(--color--light)
		);
	background-size: 100% 6px;
	content: "";
	height: 18px;
	left: 12px;
	position: absolute;
	right: 12px;
	top: 50%;
	transform: translate3d(0, -50%, 0);
	z-index: 1;
}

.VideoHeader h1 {
	background-color: var(--color--light);
	color: var(--color--dark);
	font-family: RingsideRegularSSm-Bold;
	font-size: 1.1em;
	line-height: 1;
	overflow: hidden;
	padding: 2px 18px;
	position: relative;
	text-overflow: ellipsis;
	transform: translate3d(0, -0.1em, 0);
	white-space: nowrap;
	z-index: 2;
}

@media (min-width: 0) and (max-width: 850px) {
	.VideoHeader h1 {
		font-size: 1em;
	}
}

.VideoHeader h1 em {
	font-family: RingsideRegularSSm-BoldItal;
}

.VideoBody {
	background-color: black;
	flex-grow: 1;
	overflow: hidden;
	padding-top: calc(315 / 560 * 100%);
	position: relative;
}

.VideoBody :global(.react-player) {
	left: 0;
	position: absolute;
	top: 0;
}

.ResizeHandleWrapper {
	border-bottom-right-radius: var(--border-radius--medium);
	overflow: hidden;
	transform: translate3d(-50%, -50%, 0);
}

.ResizeHandle {
	height: 20px;
	padding: 0;
	width: 20px;
}

.VideoControls {
	align-items: center;
	border-top: solid 3px var(--color--dark);
	display: flex;
	flex-shrink: 0;
	gap: 16px;
	height: 56px;
	justify-content: center;
	padding: 0 10px;
}

.VideoControl {
	align-items: center;
	background-color: var(--color--light);
	border: none;
	border-radius: var(--border-radius--small);
	box-shadow: var(--element-shadow--small);
	color: var(--color--dark);
	display: flex;
	font-family: RingsideRegular-Bold;
	font-size: 1rem;
	height: 32px;
	justify-content: center;
	width: 32px;
}

.Progress {
	background-color: var(--color--light);
	border-radius: var(--border-radius--small);
	box-shadow: var(--element-shadow--small);
	flex-grow: 1;
	height: 32px;
	padding: 2px;
}

.ProgressBarInterior {
	align-items: center;
	border-radius: var(--border-radius--small);
	display: flex;
	overflow: hidden;
	position: relative;
}

.ProgressBar {
	background-color: var(--color--dark);
	height: 28px;
	overflow: hidden;
	transition: width 200ms linear;
}

.Duration {
	align-items: center;
	border: none;
	color: var(--color--dark);
	display: flex;
	font-family: RingsideCompressed-Ultra;
	font-size: 2.6rem;
	height: 32px;
	justify-content: center;
	padding: 0 6px 1px 3px;
}
