/**
 * Keyframes
 * =================================================================================================
 */
@keyframes enter {
	from { opacity: 0; }
	to { opacity: 1; }
}

@keyframes noise {
	0% { transform: rotate(0) scale(1.4); }
	25% { transform: rotate(90deg) scale(1.4); }
	50% { transform: rotate(180deg) scale(1.4); }
	75% { transform: rotate(270deg) scale(1.4); }
	100% { transform: rotate(360deg) scale(1.4); }
}

/**
 * Release
 * =================================================================================================
 */
.Release {
	position: relative;
}

.Release--entering,
.Release--entered {
	animation-duration: 0ms;
	animation-fill-mode: both;
	animation-name: enter;
}

/**
 * Header
 * =================================================================================================
 */
.Header {
	font-family: RingsideCompressed-Ultra, -apple-system, sans-serif;
	text-transform: uppercase;
	transform: translateX(-0.05em);
}

.Header h3 {
	opacity: 0.35;
}

.Content {
	align-items: flex-start;
	display: flex;
}

.Nav {
	font-family: RingsideRegularSSm-Black;
	position: sticky;
	top: 36px;
}

.Nav a {
	display: block;
	text-decoration: none;
	transition: color 300ms cubic-bezier(0.19, 1, 0.22, 1) 600ms;
}

.Nav a:hover {
	transition-delay: 0ms;
	transition-duration: 0ms;
}

.Details {
	font-family: RingsideRegularSSm-Book;
	will-change: color;
}

.Details p {
	text-indent: 1.2em;
}

.Details :global(p.signature) {
	margin-top: 1em;
	text-indent: 0;
}

.Details p:first-child,
.Details p:first-child + p,
.Details h4 + p,
.Details figure + p,
.Details h5 + p {
	text-indent: 0;
}

.Details p:first-child em {
	font-family: RingsideRegular-BookItal;
}

.Details p:first-child strong {
	font-family: RingsideRegular-Bold;
}

.Details p:first-child em strong,
.Details p:first-child strong em {
	font-family: RingsideRegular-BoldItal;
}

.Details hr {
	background-color: inherit;
	border: none;
	color: rgba(255, 255, 255, 0.4);
	font-family: RingsideExtraWide-Book;
	margin: 1em 0;
	padding: 0;
	position: relative;
}

.Details hr::before {
	color: inherit;
	content: "* * *";
	display: block;
	text-align: center;
	white-space: nowrap;
}

.Details hr + p {
	text-indent: 0;
}

/**
 * Details: Figure
 * =================================================================================================
 */
.Details :global(figure.img--full) {
	margin: 2em 0;
}

.Details :global(figure.img--full img) {
	display: block;
	height: auto;
	width: 100%;
}

.Details :global(figure.img--full img + img) {
	margin-top: 6px;
}

.Details :global(figure figcaption) {
	color: inherit;
	font-family: RingsideRegularSSm-Book;
	opacity: 0.7;
	padding: 0 2em;
	text-align: center;
}

.Details :global(figure figcaption em) {
	font-family: RingsideRegularSSm-BookItal;
}

.Details h4 {
	box-shadow: 0 2px 0;
	margin-top: 2em;
	padding-bottom: 0.3em;
}

.Details h4 + p { margin-top: 1em; }

.Details h5 {
	margin-top: 1em;
	text-indent: -0.45em;
}

.Details small {
	-webkit-font-smoothing: subpixel-antialiased;
	letter-spacing: 0.05em;
	text-transform: uppercase;
}

.Tracks {
	counter-reset: tracks;
	list-style: decimal-leading-zero inside;
	list-style: none;
	margin-top: 1em;
}

.Tracks li {
	counter-increment: tracks;
	margin: 0;
	position: relative;
}

.Tracks li::before {
	content: counters(tracks, ".", decimal-leading-zero) ".";
	left: 0;
	position: absolute;
	top: 0;
}

.Figure {
	box-shadow: 0 54px 40px -30px rgba(0, 0, 0, 0.2);
	display: flex;
	flex-direction: column;
	margin: 24px 0 36px;
	overflow: hidden;
	position: relative;
	z-index: 1;
}

.Figure::before {
	content: "";
	float: left;
	height: 0;
	margin-left: -1px;
	padding-top: 100%;
	width: 1px;
}

.Figure::after {
	animation: noise 1.5s steps(4) infinite;
	/* background-image: url(./images/noise.png); */
	content: "";
	display: block;
	height: 100%;
	left: 0;
	opacity: 0.3;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 3;
}

.Figure .album-cover {
	transition: opacity 900ms cubic-bezier(0.19, 1, 0.22, 1) 1s;
}

.IMG {
	bottom: 0;
	display: none;
	height: 100%;
	left: 0;
	opacity: 0;
	position: absolute;
	right: 0;
	top: 0;
	transition: opacity 300ms cubic-bezier(0.19, 1, 0.22, 1) 600ms;
	width: 100%;
	will-change: opacity;
	z-index: 2;
}

.IMG::after {
	clear: both;
	content: "";
	display: table;
}

.IMG--inView {
	composes: IMG;
	animation-duration: 500ms;
	animation-fill-mode: both;
	animation-name: enter;
	display: block;
}

/**
 * Performers List
 * =================================================================================================
 */
.Performers dt {
	display: inline;
	font-size: 0.8em;
	text-transform: uppercase;
}

.Performers dd {
	display: inline;
	margin: 0;
}

.Performers dd::after {
	content: "; ";
}

.Performers dd:last-child::after {
	content: ".";
}

@media (min-width: 0) and (max-width: 767px) {
	.Header {
		font-size: 3.5rem;
		line-height: 0.9em;
		padding-left: calc(1 / 3 * 100%);
	}

	.Nav {
		font-size: 1.1rem;
		line-height: 1.9em;
		width: calc(2 / 6 * 100%);
	}

	.Content {
		font-size: 1.3rem;
		line-height: calc(19 / 13);
		padding-top: 12px;
	}

	.Details { flex: 1; }
	.Details small { font-size: 0.8em; }
	.Details p:first-child small { font-size: 0.75em; }

	.Details p:first-child {
		font-family: RingsideRegularSSm-Book;
		font-size: 1.6rem;
		line-height: calc(22 / 16);
		margin-bottom: 1em;
	}

	.Details p:first-child em { font-family: RingsideRegularSSm-BookItal; }
	.Details p:first-child strong { font-family: RingsideRegularSSm-Bold; }

	.Details p:first-child em strong,
	.Details p:first-child strong em { font-family: RingsideRegularSSm-BoldItal; }

	.Details h4,
	.Details h5 { font-family: RingsideRegularSSm-Bold, -apple-system, sans-serif; }
	.Details p em { font-family: RingsideRegularSSm-BookItal; }
	.Details p em em { font-family: RingsideRegularSSm-Book; }
	.Details p strong { font-family: RingsideRegularSSm-Bold; }

	.Details p em strong,
	.Details p strong em { font-family: RingsideRegularSSm-BoldItal; }

	.Tracks li {
		margin: 0;
		padding-left: 1.8em;
	}

	.Tracks li::before {
		font-family: RingsideRegularSSm-Med, -apple-system, sans-serif;
		font-size: 0.7em;
		padding-right: 0.9em;
		padding-top: 0.42em;
		position: absolute;
	}

	.Performers {
		margin-top: 0.7em;
	}

	.Performers dt {
		font-family: RingsideRegularSSm-Bold, -apple-system, sans-serif;
		margin-right: 0.3em;
	}

	.Performers dd {
		font-family: RingsideRegularSSm-Book, -apple-system, sans-serif;
	}

	.Details :global(figure figcaption) {
		font-size: 10px;
		line-height: 1.3;
		padding-top: 12px;
	}
}

@media (min-width: 768px) and (max-width: 1023px) {
	.Header {
		font-size: 4rem;
		line-height: 0.9em;
		padding-left: calc(1 / 3 * 100%);
	}

	.Nav {
		font-size: 1.4rem;
		line-height: calc(23 / 14);
		width: calc(2 / 6 * 100%);
	}

	.Content {
		font-size: 1.6rem;
		line-height: calc(24 / 16);
		padding-top: 36px;
	}

	.Details { flex: 1; }
	.Details small { font-size: 0.8em; }
	.Details p:first-child small { font-size: 0.75em; }

	.Details div > p:first-child {
		font-family: RingsideRegular-Book;
		font-size: 2.2rem;
		line-height: calc(28 / 22);
		margin-bottom: 1.5em;
	}

	.Details p em { font-family: RingsideRegularSSm-BookItal; }
	.Details p em em { font-family: RingsideRegularSSm-Book; }
	.Details p strong { font-family: RingsideRegularSSm-Bold; }

	.Details p em strong,
	.Details p strong em { font-family: RingsideRegularSSm-BoldItal; }

	.Details h4,
	.Details h5 { font-family: RingsideRegularSSm-Bold, -apple-system, sans-serif; }

	.Tracks li {
		margin: 0;
		padding-left: 1.8em;
	}

	.Tracks li::before {
		font-family: RingsideRegularSSm-Med, -apple-system, sans-serif;
		font-size: 0.7em;
		padding-right: 0.9em;
		padding-top: 0.42em;
		position: absolute;
	}

	.Performers {
		margin-top: 0.7em;
	}

	.Performers dt {
		font-family: RingsideRegularSSm-Bold, -apple-system, sans-serif;
		margin-right: 0.3em;
	}

	.Performers dd {
		font-family: RingsideRegularSSm-Book, -apple-system, sans-serif;
	}

	.Details :global(figure figcaption) {
		font-size: 12px;
		line-height: 1.5;
		padding-top: 18px;
	}
}

@media (min-width: 1024px) and (max-width: 1365px) {
	.Header {
		font-size: 4.8rem;
		line-height: calc(48 / 48);
		padding-left: calc(1 / 3 * 100%);
		padding-right: calc(1 / 6 * 100%);
	}

	.Nav {
		line-height: calc(30 / 19);
		width: calc(2 / 6 * 100%);
	}

	.Content {
		font-size: 1.9rem;
		line-height: calc(25 / 19);
		padding-top: 36px;
	}

	.Details {
		font-family: RingsideRegular-Book;
		width: calc(3 / 6 * 100%);
	}

	.Details small { font-size: 0.75em; }
	.Details p em { font-family: RingsideRegular-BookItal; }
	.Details p em em { font-family: RingsideRegular-Book; }
	.Details p strong { font-family: RingsideRegular-Bold; }

	.Details p em strong,
	.Details p strong em { font-family: RingsideRegular-BoldItal; }

	.Details p:first-child {
		font-family: RingsideRegular-Book;
		font-size: 2.4rem;
		line-height: calc(31 / 24);
		margin-bottom: 1em;
	}

	.Details h4,
	.Details h5 { font-family: RingsideRegular-Bold, -apple-system, sans-serif; }

	.Tracks li {
		margin: 0;
		padding-left: 1.8em;
	}

	.Tracks li::before {
		font-family: RingsideRegular-Med, -apple-system, sans-serif;
		font-size: 0.7em;
		padding-right: 0.9em;
		padding-top: 0.42em;
		position: absolute;
	}

	.Performers {
		margin-top: 0.7em;
	}

	.Performers dt {
		font-family: RingsideRegular-Bold, -apple-system, sans-serif;
		margin-right: 0.3em;
	}

	.Performers dd {
		font-family: RingsideRegular-Book, -apple-system, sans-serif;
	}

	.Details :global(figure figcaption) {
		font-size: 12px;
		line-height: 1.5;
		padding-top: 18px;
	}
}

@media (min-width: 1366px) and (max-width: 1919px) {
	.Release {
		margin-left: calc(1 / 6 * 100%);
		margin-right: calc(1 / 6 * 100%);
		position: relative;
	}

	.Header {
		font-size: 5.2rem;
		line-height: calc(46 / 48);
		padding-left: calc(1 / 4 * 100%);
		padding-right: calc(1 / 6 * 100%);
	}

	.Nav {
		line-height: calc(30 / 19);
		width: calc(1 / 4 * 100%);
	}

	.Content {
		font-size: 1.9rem;
		line-height: calc(25 / 19);
		padding-top: 36px;
	}

	.Details {
		flex: 1;
		font-family: RingsideRegular-Book;
		padding-right: calc(1 / 4 * 100%);
	}

	.Details small { font-size: 0.75em; }
	.Details p em { font-family: RingsideRegular-BookItal; }
	.Details p em em { font-family: RingsideRegular-Book; }
	.Details p strong { font-family: RingsideRegular-Bold; }

	.Details p em strong,
	.Details p strong em { font-family: RingsideRegular-BoldItal; }

	.Details div > p:first-child {
		font-size: 2.2rem;
		line-height: calc(30 / 22);
		margin-bottom: 1.5em;
	}

	.Details h4,
	.Details h5 { font-family: RingsideRegular-Bold, -apple-system, sans-serif; }

	.Tracks li {
		margin: 0;
		padding-left: 1.8em;
	}

	.Tracks li::before {
		font-family: RingsideRegularSSm-Med, -apple-system, sans-serif;
		font-size: 0.7em;
		padding-right: 0.9em;
		padding-top: 0.42em;
		position: absolute;
	}

	.Performers {
		margin-top: 0.7em;
	}

	.Performers dt {
		font-family: RingsideRegular-Bold, -apple-system, sans-serif;
		margin-right: 0.3em;
	}

	.Performers dd {
		font-family: RingsideRegular-Book, -apple-system, sans-serif;
	}

	.Details :global(figure figcaption) {
		font-size: 12px;
		line-height: 1.5;
		padding-top: 18px;
	}
}

@media (min-width: 1920px) {
	.Release {
		flex: 1;
		order: 1;
	}

	.Header {
		font-size: 4.8rem;
		line-height: calc(48 / 48);
		padding-left: calc(1 / 4 * 100%);
	}

	.Nav {
		line-height: calc(30 / 19);
		width: calc(1 / 4 * 100%);
	}

	.Content {
		font-size: 1.9rem;
		line-height: calc(25 / 19);
		padding-top: 36px;
	}

	.Details {
		flex: 1;
		font-family: RingsideRegular-Book;
	}

	.Details small { font-size: 0.75em; }
	.Details p em { font-family: RingsideRegular-BookItal; }
	.Details p em em { font-family: RingsideRegular-Book; }
	.Details p strong { font-family: RingsideRegular-Bold; }

	.Details p em strong,
	.Details p strong em { font-family: RingsideRegular-BoldItal; }

	.Details p:first-child {
		font-family: RingsideRegular-Book;
		font-size: 2.4rem;
		line-height: calc(31 / 24);
		margin-bottom: 1em;
	}

	.Details h4,
	.Details h5 { font-family: RingsideRegular-Bold, -apple-system, sans-serif; }

	.Tracks li {
		margin: 0;
		padding-left: 1.8em;
	}

	.Tracks li::before {
		font-family: RingsideRegular-Med, -apple-system, sans-serif;
		font-size: 0.7em;
		padding-right: 0.9em;
		padding-top: 0.42em;
		position: absolute;
	}

	.Performers {
		margin-top: 0.7em;
	}

	.Performers dt {
		font-family: RingsideRegular-Bold, -apple-system, sans-serif;
		margin-right: 0.3em;
	}

	.Performers dd {
		font-family: RingsideRegular-Book, -apple-system, sans-serif;
	}

	.Details :global(figure figcaption) {
		font-size: 12px;
		line-height: 1.5;
		padding-top: 18px;
	}
}

@media (min-width: 2000px) {
	.Header,
	.Figure,
	.Content {
		width: calc(4 / 6 * 100%);
	}

	.Header {
		padding-left: calc(1 / 6 * 100%);
	}
}
